<template>
  <div class="bg-smoke rounded mb-3">
    <Details
      :left="firstLine.left"
      :right="firstLine.right"
      :divider="number > 0"
      :aClass="'font-weight-bold --medium-gray col-6 pb-2'"
    />
<!--    <v-divider class="mx-4 bg-dark"/>-->
    <Details
      v-if="number > 0"
      :left="$t('reservationAndUsageHistory.numberOfCars')"
      :right="`${number}${$t('common.stand')}`"
      :aClass="'font-weight-bold --medium-gray col-6'"
    />
    <div v-if="firstLine.remarks" class="pa-4 bg-silver">
      <FormatSpan :value="firstLine.remarks"/>
    </div>
  </div>
</template>

<script>
import FormatSpan from '../Common/FormatSpan.vue'
import Details from './index'

export default {
  name: 'Parking',
  components: {
    Details,
    FormatSpan
  },
  props: {
    facilityType: String,
    noParking: Boolean,
    number: {
      String
    }
  },
  computed: {
    firstLine () {
      if (this.number > 0) {
        return {
          left: this.$t('reservationAndUsageHistory.noParkingRequired'),
          right: this.$t('common.necessary')
        }
      } else if (this.noParking) {
        return {
          left: this.$t('reservationAndUsageHistory.parking'),
          right: 'なし'
        }
      } else if (this.facilityType === 'CNV') {
        return {
          left: this.$t('reservationAndUsageHistory.parking'),
          right: '予約不要※',
          remarks: `※施設によっては専用駐車場を設けていない施設もございます。
台数や料金など詳しくは各施設の公式HPをご覧ください。`
        }
      } else {
        return {
          left: this.$t('reservationAndUsageHistory.noParkingRequired'),
          right: this.$t('common.unnecessary')
        }
      }
    }
  }
}
</script>
