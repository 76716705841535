<template>
  <div class="form-container mb-16">
    <Stage :stage="4"/>
    <div class="text-center text-heading --w-bold --prm py-5">{{ $t('booking.finalConfirmation') }}</div>

    <div class="px-5">
      <!-- Reservation Details START-->
      <UsageHistoryItem
        :id="facilityInfo.id"
        :title="facilityInfo.name"
        :urlImg="facilityInfo.mainImageUrl"
        :roomTypeName="roomType.name"
        :checkInDate="checkInDate"
        :checkOutDate="checkOutDate"
        class="px-0"
        :isActive="true"
      />
      <div v-for="(booking, bookingInd) in bookings" :key="bookingInd">
        <h3 v-if="bookings.length > 1" class="text-heading --prm mb-2">{{ $t('title.reservation') }}{{ bookingInd + 1 }}</h3>
        <div class="form-container mb-6">
          <ReservationDetailItem
            v-for="(guest, guestInd) in booking.guests"
            :key="guestInd"
            :left="guest.representative
              ? $t('reservationAndUsageHistory.accommodationRepresentative')
              : `${ $t('reservationAndUsageHistory.guest') }${ guestInd + 1 }`
            "
            :right="guest.name"
            :aClass="'text-title font-weight-bold --bg'"
            :cardClass="guestInd === 0 ? 'rounded-t' : (guestInd === booking.guests.length - 1 ? 'rounded-b' : '')"
            :divider="guestInd !== booking.guests.length - 1"
          />
          <ReservationDetailItem
            :left="$t('common.totalPeople')"
            :right="booking.guests.length + $t('common.people')"
            :aClass="'text-title font-weight-bold --bg'"
            :divider="true"
            :notWhiteBg="true"
          />
        </div>
        <BookingPlan
          v-if="booking.bookingPlan"
          :bookingPlan="booking.bookingPlan"
        />
        <RentalList
          v-for="(rentalGroup, rentalGroupInd) in booking.rentalItems"
          :key="rentalGroupInd"
          :index="rentalGroupInd"
          :rental="getRentalItemNames(rentalGroup)"
          :schedule="getRentalSchedule(rentalGroup)"
        />

        <Parking
          :facilityType="facilityInfo.type"
          :noParking="facilityInfo.noParking"
          :number="getParkingCount(booking)"
        />
        <PetInfo v-if="acceptsPet && !showOldPetInfo" :petInfo="booking.pets" :catAllowed="facilityInfo.catAllowed"/>

        <BookingPriceSummary :bookingId="booking.id" hidePayments />
      </div>

      <div class="--bg pt-4">{{ $t('reservationAndUsageHistory.allListedPricesIncludeTax') }}</div>
      <div class="--bg pb-4"><other-tax-disclaimer class="mt-4" :facility="facilityInfo" /></div>

      <!-- <PointBalance v-if="isTvpPaymentAllowed" :point="tvp"/> -->
      <BookingPrePayment v-if="isTvpPaymentAllowed"
        v-model="bookingPrePayments"
        :accommodationFeePerBooking="accommodationFeePerBooking"
        :currentPoint="tvp"
        :acceptsSameDayBooking="facilityInfo.acceptsSameDayBooking"
      />
      <!-- Reservation Details END-->

      <!-- removed, see uat customer-72 -->
      <!-- <v-checkbox
        :label="$t('booking.sameRoomOnSameBookingBeforeOrAfterDay')"
        v-model="requestSameRoom"
      ></v-checkbox>

      <div class="pb-13">
        {{ $t('booking.sameRoomAvailableText') }}
      </div> -->

      <!-- Contact START-->
      <Contact />
      <!-- Contact  END-->

      <div class="bg-white" v-if="$isCompanyUser()">
        <div class="pa-4 text-title">
          ご利用者様の現地お支払い方法については<a :href="remarksUrl" target="_blank">こちら</a>をご確認ください。
        </div>
      </div>

      <v-btn
        elevation="0"
        class="text-heading my-12 --w-bold"
        @click="confirmBookingGroup"
        rounded
        block
        color="btn-grad--orange"
        x-large
      >
        <span class="text-heading font-weight-medium w-100 --white">{{ $t('buttons.confirmBooking') }}</span>
        <IconChevronRightCircle color="white" width="23" height="23" class="mr-2"/>
      </v-btn>
      <div>
        {{ $t('booking.ifHaveSpecRequest') }}
        <a href="#" @click.prevent="openCallCenter">
          {{ $t('booking.callCenter') }}
        </a>
        {{ $t('booking.contactUsAt') }}
      </div>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/Stage'
import UsageHistoryItem from '@/components/UsageHistoryItem'
import Contact from '@/components/MemberItem/Contact'
import searchVacancyMixin from '../searchVacancyMixin'
import withTemporaryBookingMixin from '../withTempraryBookingMixin'
import { toThousands } from '@/filters'
import { summarizePricesByDate } from '@/utils/prices'
import RentalList from '@/components/Details/RentalList'
import Parking from '@/components/Details/Parking'
import ReservationDetailItem from '@/components/Details'
import { jaLongDateRange } from '@/utils/get-date'
import IconChevronRightCircle from '@/components/icons/IconChevronRightCircle'
import OtherTaxDisclaimer from '@/components/Facility/OtherTaxDisclaimer.vue'
import BookingPlan from '@/components/Details/BookingPlan'
import PetInfo from '@/components/Details/PetInfo'
import BookingPrePayment from '@/components/Details/BookingPrePayment.vue'
import BookingPriceSummary from '@/components/Details/BookingPriceSummary.vue'

export default {
  name: 'FinalConfirmation',
  mixins: [searchVacancyMixin, withTemporaryBookingMixin],
  components: {
    Stage,
    UsageHistoryItem,
    BookingPrePayment,
    Contact,
    RentalList,
    Parking,
    ReservationDetailItem,
    IconChevronRightCircle,
    OtherTaxDisclaimer,
    BookingPlan,
    PetInfo,
    BookingPriceSummary
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const { totalPrice, prices, accommodationFeePerBooking } = await this.$store.dispatch('calculateAccommodationPrice')
        this.totalPrice = totalPrice
        this.prices = prices
        this.accommodationFeePerBooking = accommodationFeePerBooking
      })
    })
  },
  data () {
    return {
      totalPrice: null,
      notWhiteBg: false,
      requestSameRoom: false,
      feePaymentType: null,
      bookingPrePayments: [],
      accommodationFeePerBooking: []
    }
  },
  computed: {
    remarksUrl () {
      return process.env.VUE_APP_BOOKING_PAYMENT_REMARKS_URL
    },
    chargeTotal () {
      return this.charges.reduce((sum, c) => {
        if (c.unit === 'jpy') return sum + c.value
        return sum
      }, 0)
    },
    charges () {
      const accommodationFee = {
        name: this.isPointBasedProduct ? this.$t('booking.usedPoints') : this.$t('booking.usageFees'),
        value: this.totalPrice,
        unit: this.isPointBasedProduct ? 'pt' : 'jpy'
      }
      const summarized = summarizePricesByDate(this.prices)

      const { totalPrice, charges } = this.$store.getters.rentalItemSummary

      const result = [
        accommodationFee,
        ...summarized.map(charge => ({
          name: charge.subject,
          value: charge.price,
          unit: 'jpy'
        }))
      ]
      if (totalPrice) {
        result.push({
          name: this.$t('common.rentalListingTotal'),
          value: totalPrice,
          unit: 'jpy',
          details: charges.map(c => ({
            name: c.name,
            value: `${toThousands(c.price)}${this.$t('reservationAndUsageHistory.yen')}`
          }))
        })
      }

      return result
    },
    tvp () {
      return this.$store.getters.remainingTvp
    }
  },
  methods: {
    async confirmBookingGroup () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('confirmBookingGroup', {
            requestSameRoom: this.requestSameRoom,
            requestMailCertificate: false,
            feePaymentType: this.feePaymentType,
            bookingPrePayments: this.bookingPrePayments
          })
          await this.$router.push({ name: 'home-vacancy-search-reservation-complete' })
        })
      })
    },
    openCallCenter () {
      this.$store.commit('showCallCenterDialog')
    },
    getRentalItemNames (rentalGroup) {
      return rentalGroup.selects &&
        rentalGroup.selects.map(s => `${s.rentalItemDef.name}×${s.quantity}個`).join(', ')
    },
    getRentalSchedule (rentalGroup) {
      return jaLongDateRange(rentalGroup.fromDate, rentalGroup.toDate)
    },
    getParkingCount (booking) {
      if (typeof booking.parkings === 'number') {
        return booking.parkings
      } else {
        return booking.parkings?.length ?? 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__slot {
    label {
      font-size: var(--font-size__title);
      font-weight: var(--font-weight__bold) !important;
      color: var(--color__black-gray);
    }
    i {
      font-size: var(--font-size__huge);
    }
  }
  .v-input--selection-controls__input {
    background: white;
  }
}

.charge-details {
  width: 50px;
  display: inline-block;
}
</style>
