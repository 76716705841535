<template>
  <div>
    ※下記施設は上記金額に別途宿泊税・入湯税をご請求させて頂く場合がございます。<br>
    &nbsp;【宿泊税対象】金沢、東京二子玉川、熱海（2025年4月1日より）、京都、大阪御堂筋、京都洛龍菴<br>
    &nbsp;【入湯税対象】那須、箱根強羅、熱海、伊豆高原、伊豆今井浜
  </div>
</template>

<script>
export default {
  props: {
    facility: Object
  },
  computed: {
    message () {
      // now we dont use this logic, see https://overflow.io/s/542E57PQ?node=ea112913&thread=50899422-7246-4e78-a705-3cd586c19402&comment=fe8eafbc-8e22-4b99-8c2d-df2a7840c5e1&comment=fe8eafbc-8e22-4b99-8c2d-df2a7840c5e1
      if (this.facility.hotelTaxEnabled) {
        if (this.facility.showBathingSchedule) {
          return '※ご利用の施設は上記金額とは別途宿泊税・入湯税をご請求させていただく場合がございます。'
        }
        return '※ご利用の施設は上記金額とは別途宿泊税をご請求させていただく場合がございます。'
      } else if (this.facility.showBathingSchedule) {
        return '※ご利用の施設は上記金額とは別途入湯税をご請求させていただく場合がございます。'
      }

      return ''
    }
  }
}
</script>
