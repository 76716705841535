import gql from 'graphql-tag'

export const SEARCH_VACANCY = gql`
  query (
    $fromDate: DateString! # search from date
    $toDate: DateString! # search to date
    $contractId: Int! #ID of contract Int!
    $tags: [String!] # search tag 検索タグ [String!]
    $facilityIds: [Int!] # Facility ID 施設ID [Int!]
    $numberOfAdults: Int! # num of adult 大人 Int
    $numberOfChildren: Int! # num of Child 子供なし子供
    $numberOfBedShare: Int! # num of Child bed share 添い寝あり子供
    $numberOfPriceAndAvailability: Int # num of price availability
  ) {
    bookingAvailableFacilityList (
      fromDate: $fromDate
      toDate: $toDate
      contractId: $contractId
      tags: $tags
      facilityIds: $facilityIds
      numberOfAdults: $numberOfAdults
      numberOfChildren: $numberOfChildren
      numberOfBedShare: $numberOfBedShare
      numberOfPriceAndAvailability: $numberOfPriceAndAvailability
    ) {
      id
      name: nameOnClientWeb # Facility Name 施設名
      type
      noParking
      tags # tags タグ string array
      mainImageUrl
      facilityHpUrl # Link to facility information page 施設情報ページのリンク
      address1
      hotelTaxEnabled
      showBathingSchedule
      postalCode
      maxPetCount
      hasPetWeightRestriction
      petTermUrl
      catAllowed
      acceptsSameDayBooking
  
      roomTypes {
        id # Room Type ID (used for reservation) 部屋タイプID（予約で使用します）
        name # Room type name 部屋タイプ名
        maxOccupancy
        bedShareMaxOccupancy
        acceptsPet
        maxPetCount
        alert
        priceAndAvailability {
          stayDate # StayDate 宿泊日
          contractPrice
          roomsAvailable
        }
      }
    }
  }
`

export const GET_SEARCH_TAGS = gql`
  query {
    enumFacilitySearchTagList { name }
  }
`

export const GET_FACILITY_LIST = gql`
  query {
    publicFacilityList {
      id name shortName
    }
  }
`

export const CALCULATE_PRICE = gql`
  mutation (
    $contractId: Int!
    $roomTypeId: Int!
    $checkInDate: DateString! # DateString!
    $checkOutDate: DateString! #DaetString!
    $numberOfAdults: Int!
    $numberOfChildren: Int!
    $numberOfBedShare: Int!
    $bookingPlanId: Int  # Null for without plan プラン未使用はNull
    $bookingId: Int  #Each reservation ID 各予約ID
  ) {
    calculateMyAccommodationPrice (
      contractId: $contractId
      roomTypeId: $roomTypeId
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
      numberOfAdults: $numberOfAdults
      numberOfChildren: $numberOfChildren
      numberOfBedShare: $numberOfBedShare
      bookingPlanId: $bookingPlanId
      bookingId: $bookingId
    ) {
      pricesByDay {
        date
        accommodationFee
        fees {
          subject
          price
          taxIncluded
          taxType
          priceWithSalesTax
        }
      }
    }
  }
`

export const GET_AVAILABLE_PLANS = gql`
  query (
    $roomTypeId: Int! #Room Type ID 部屋タイプID Int!
    $checkInDate: DateString! #  Check-in date チェックイン日 DateString!
    $checkOutDate: DateString! #  Check-out date  チェックアウト日 DateString!
    $contractId: Int! #契約のID Int!
    $rooms: [AvailableBookingPlanRoomsInput!]!
  ) {
    availableBookingPlanList(filter : {
      roomTypeId: $roomTypeId
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
      contractId: $contractId
      rooms: $rooms
    }) {
      items {
        id
        title
        subtitle # Sub title サブタイトル
        description # Description 説明
        remarks
        prices {
          stayDate # Stay date 宿泊日
          accommodationPrice # Price of JPY JPYでの価格
          accommodationPriceSp # Price of SP SPの価格
          accommodationPriceFp # Price of FP FPの価格
          bookingPlanFee {
            price # Expenses attached to the plan (always JPY) (to be recorded as other expenses) プランに付属する費用(常にJPY)（その他費用として計上すること）
          }
        }
        imagePublicUrl1
        imagePublicUrl2
        question1Text
        question1Type
        question1Required
        question1Select1
        question1Select2
        question1Select3
        question1Select4
        question1Select5
        question2Text
        question2Type
        question2Required
        question2Select1
        question2Select2
        question2Select3
        question2Select4
        question2Select5
        question3Text
        question3Type
        question3Required
        question3Select1
        question3Select2
        question3Select3
        question3Select4
        question3Select5
        question4Text
        question4Type
        question4Required
        question4Select1
        question4Select2
        question4Select3
        question4Select4
        question4Select5
        question5Text
        question5Type
        question5Required
        question5Select1
        question5Select2
        question5Select3
        question5Select4
        question5Select5
      }
    }
  }
`

export const CREATE_TEMPORARY_BOOKING = gql`
  mutation (
    $roomTypeId: Int!
    $bookingPlanId: Int
    $checkInDate: DateString!
    $checkOutDate: DateString!
    $contractId: Int!
    $rooms: [CreateTemporaryBookingRoomInput!]! # array of the number of adult, child, bedshare
  ) {
    createTemporaryBookingGroup(
      roomTypeId: $roomTypeId
      bookingPlanId: $bookingPlanId
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
      contractId: $contractId # Contract ID 契約ID
      rooms: $rooms
    ) {
      id # Reservation group ID (use this to confirm, etc.) 予約グループID（これを利用して確定など行う）
      bookings {
        id # Reservation ID, which is also used to process the reservation confirmation. 予約ID、これも利用して予約確定処理を行う
        checkInDate
        checkOutDate
        bookingTypeId
        guests { # guest 宿泊者
          id # Guest ID, which is also used for confirmation. 宿泊者ID、これも確定時に利用する。
          representative # Boolean! True for representative, False for others 代表者はTrue,それ以外はFalse
          child # Boolean! True for children (including Children sleeping with parents), False for other children (adults). 子供（添い寝を含む)はTrue,それ以外（大人）はFalse
          bedShared # Boolean! true for children sleeping together, false for others 添い寝子供はtrue,それ以外はFalse
        }
      }
    }
  }
`

export const EXTEND_TEMPORARY_BOOKING = gql`
  mutation (
    $bookingGroupId: Int!
  ) {
    extendBookingGroup(id: $bookingGroupId)
  }
`

export const REMOVE_TEMPORARY_BOOKING = gql`
  mutation ($bookingGroupId: Int!) {
    removeTemporaryBookingGroup (id: $bookingGroupId)
  }
`

export const CONFIRM_BOOKING_GROUP = gql`
  mutation (
    $bookingGroupId: Int!
    $requestSameRoom: Boolean!
    $requestMailCertificate: Boolean!
    $feePaymentType: FeePaymentType
    $bookings: [ConfirmBookingGroupBookingInput!]!
  ) {
    confirmBookingGroup (
      id: $bookingGroupId
      bookings: $bookings
      requestSameRoom: $requestSameRoom
      requestMailCertificate: $requestMailCertificate
      feePaymentType: $feePaymentType
    )
  }
`

export const RENTAL_ITEM_LIST = gql`
  query (
    $facilityId: Int!
    $checkInDate: DateString!
    $checkOutDate: DateString!
  ){
    clientRentalItemList (
      facilityId: $facilityId
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
    ) {
      id
      quantity
      availabilities {
        stayDate
        quantity
      }
      rentalItemDef {
        name
        price
        rentalPeriod
      }
    }
  }
`

export const PARKING_LIST = gql`
  query (
    $facilityId: Int!
    $checkInDate: DateString!
    $checkOutDate: DateString!
  ) {
    clientFacilityParkingList (
      facilityId: $facilityId
      checkInDate: $checkInDate
      checkOutDate: $checkOutDate
    ) {
      id
      name
      quantity
      maxHeight

      availabilities {
        stayDate
        quantity
      }
    }
  }
`

export const GET_CANCEL_POLICY = gql`
  query (
    $contractId: Int!
    $checkInDate: DateString!
    $bookingPlanId: Int
  ) {
    myCancelPolicy (
      contractId: $contractId
      checkInDate: $checkInDate
      bookingPlanId: $bookingPlanId
    ) {
       noShowPenalty
       day0Penalty
       thresholds {
         day
         penalty
       }
       appliedToday
    }
  }
`

export const GET_BOOKING_CANCEL_POLICY = gql`
  query (
    $bookingId: Int!
    $code: String!
  ) {
    bookingCancelPolicy (
      bookingId: $bookingId
      code: $code
    ) {
       noShowPenalty
       day0Penalty
       thresholds {
         day
         penalty
       }
       appliedToday
    }
  }
`
